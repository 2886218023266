<template>
  <div class="d-flex justify-content-center">
    <div>
    <div class="spinner-border align-middle pj-spinner-border" role="status"></div>
    <strong class="align-middle pj-spinner-border-text">Загружаю промокоды...</strong>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pj-spinner-border-text{
  padding-left: 1rem;
}
.pj-spinner-border,
.pj-spinner-border-text {
  color: $primary-light;
}
@media (prefers-color-scheme: dark) {
  .pj-spinner-border,
  .pj-spinner-border-text {
    color: $primary-dark;
  }
}
</style>
