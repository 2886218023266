<template>
  <Suspense timeout="0">
    <template #default>
      <div class="container">
        <div class="card pg-head-card mb-3">
          <div class="card-body">
            <h1 class="display-4 pj-display-4">Промокоды пиццерий Минска 🍕</h1>
            <h2 class="card-title lead">
              На нашем сайте представлены актуальные промокоды пиццерий Минска
            </h2>
            <div class="card-text" v-if="store.state.allPromoCodes">
              Доступных промокодов: {{ store.getters.allPromoCodesLength }}
            </div>
          </div>
          <div class="card-footer" v-if="store.state.updateDate">
            💡 Последнее обновление промокодов:
            {{ getRelativeDateFromNow(store.state.updateDate) }}
          </div>
        </div>
        <PromoCodes />
      </div>
    </template>

    <template #fallback>
      <div class="container">
        <div class="card pg-head-card mb-3">
          <div class="card-body">
            <h1 class="display-4" style="font-size: 42px">
              Промокоды пиццерий Минска 🍕
            </h1>
            <h2 class="card-title lead">
              На нашем сайте представлены актуальные промокоды пиццерий Минска
            </h2>
            <div class="card-text">
              Доступных промокодов:
              <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <div class="card-footer">
            💡 Последнее обновление промокодов:
            <div class="spinner-grow spinner-grow-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <PromoCodesLoader />
      </div>
    </template>
  </Suspense>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
require("dayjs/locale/ru");
dayjs.locale("ru");

import PromoCodesLoader from "@/components/loaders/PromoCodesLoader.vue";
import { defineAsyncComponent } from "vue";
import { store } from "@/store/store";

const PromoCodes = defineAsyncComponent(() =>
  import(/* webpackChunkName: "promoCodes" */ "@/components/PromoCodes.vue")
);

export default {
  name: "Index",
  async setup() {
    return {
      store,
    };
  },
  components: {
    PromoCodes,
    PromoCodesLoader,
  },
  methods: {
    getDateDDMMYYYY(value) {
      return dayjs(value).format("DD.MM.YYYY");
    },
    getRelativeDateFromNow(value) {
      return dayjs(value).fromNow();
    },
  },
};
</script>

<style lang="scss">


body {
  background-image: url("../assets/pizza.svg");
  background-size: 100px;
  background-position: center;
}
.pj-display-4 {
  font-size: 42px;
}
.pg-head-card {
  color: #fff;
  background: $primary-light;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #19191a;
  }
  .pg-head-card {
    background: $primary-dark;
  }
}
</style>
