<template>
  <ul class="nav justify-content-center" id="nav">
    <li class="nav-item">
      <router-link to="/" class="nav-link">Главная</router-link>
    </li>
    <li class="nav-item">
      <router-link to="/about" class="nav-link">О нас</router-link>
    </li>
  </ul>
  <Suspense timeout="0">
    <template #default>
      <router-view />
    </template>
    <template #fallback>
      <div class="app-splash-screen">
        Привет, я загрузочный экран, подожди немного:)
      </div>
    </template>
  </Suspense>
</template>

<style lang="scss">

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  a {
    font-weight: bold;
    color: #6c757d;

    &.router-link-exact-active {
      color: $primary-light;
      text-decoration: none;
    }
  }
}

.app-splash-screen {
  display: flex;
  justify-content: center;
}

@media (prefers-color-scheme: dark) {
  #nav {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  a {
    font-weight: bold;
    color: #6c757d;

    &.router-link-exact-active {
      color: $primary-dark;
      text-decoration: none;
    }
  }
}

}

</style>
