import { reactive, computed, readonly } from "vue";
import axios from "axios";

const state = reactive({
  visiblePromoCodes: [],
  filteredPromoCodes: [],
  allPromoCodes: [],

  sliceFrom: 0,
  sliceTo: 10,

  updateDate: null,
  banner: null,

  error: null
});

async function getPromoCodesFromApi() {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_PJ_BASE_API_URL}get_promo_codes`,
      {
        timeout: 60000
      }
    );
    state.allPromoCodes = response.data.promo_codes;
    state.updateDate = response.data.update_date.$date;
    state.banner = response.data.banner;

    state.filteredPromoCodes = state.allPromoCodes;
    state.visiblePromoCodes = state.filteredPromoCodes.slice(
      state.sliceFrom,
      state.sliceTo
    );
  } catch (e) {
    console.log(e);
    state.error = e;
  }
}

function loadMore() {
  state.sliceFrom = state.sliceTo;
  state.sliceTo = state.sliceTo + 10;
  state.visiblePromoCodes.push(
    ...state.filteredPromoCodes.slice(state.sliceFrom, state.sliceTo)
  );
}

const allPromoCodesLength = computed(() => state.allPromoCodes.length);
const filteredPromoCodesLength = computed(
  () => state.filteredPromoCodes.length
);

function filterPromocodes(category, sortBy, amountFrom, amountTo) {
  switch (category) {
    case "all":
      filterAllPromoCodes();
      break;
    case "good":
      filterGoodPromocodes();
      break;
    case "discount":
      filterDiscountPromocodes();
      break;
    default:
      break;
  }

  switch (sortBy) {
    case "new":
      break;
    case "cheap":
      sortFromCheapToExpensive();
      break;
    case "expensive":
      sortFromExpensiveToCheap();
  }

  filterPromocodesByAmount(amountFrom, amountTo);

  zeroOutSlices();
  setVisiblePromoCodes();
}

function setVisiblePromoCodes() {
  state.visiblePromoCodes = state.filteredPromoCodes.slice(
    state.sliceFrom,
    state.sliceTo
  );
}

function sortFromCheapToExpensive() {
  state.filteredPromoCodes.sort(
    (a, b) => a.conditions[0].value - b.conditions[0].value
  );
}
function sortFromExpensiveToCheap() {
  state.filteredPromoCodes.sort(
    (a, b) => b.conditions[0].value - a.conditions[0].value
  );
}

function zeroOutSlices() {
  state.sliceFrom = 0;
  state.sliceTo = 10;
}

function getAllPromoCodes() {
  return state.allPromoCodes;
}
function getGoodPromocodes() {
  return state.allPromoCodes.filter(
    item => item.rewards && item.rewards[0]["reward"] === "good"
  );
}
function getDiscountPromocodes() {
  return state.allPromoCodes.filter(
    item => item.rewards && item.rewards[0]["reward"] === "order_discount"
  );
}
function getPromocodesByAmount(promocodes, amountFrom, amountTo) {
  return promocodes.filter(function(item) {
    return (
      amountFrom <= parseInt(item.conditions[0].value) &&
      amountTo >= parseInt(item.conditions[0].value)
    );
  });
}

function filterAllPromoCodes() {
  state.filteredPromoCodes = getAllPromoCodes();
}
function filterGoodPromocodes() {
  state.filteredPromoCodes = getGoodPromocodes();
}
function filterDiscountPromocodes() {
  state.filteredPromoCodes = getDiscountPromocodes();
}
function filterPromocodesByAmount(amountFrom, amountTo) {
  state.filteredPromoCodes = getPromocodesByAmount(
    state.filteredPromoCodes,
    amountFrom,
    amountTo
  );
}

const minAmountFrom = computed(() =>
  Math.min.apply(
    Math,
    state.filteredPromoCodes.map(function(o) {
      return o.conditions[0].value;
    })
  )
);
const maxAmountTo = computed(() =>
  Math.max.apply(
    Math,
    state.filteredPromoCodes.map(function(o) {
      return o.conditions[0].value;
    })
  )
);

export const store = readonly({
  state: state,
  getters: {
    allPromoCodesLength,
    filteredPromoCodesLength,
    minAmountFrom,
    maxAmountTo
  },
  actions: {
    getPromoCodesFromApi,
    loadMore,
    filterPromocodes,
    zeroOutSlices
  }
});
